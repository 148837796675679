<template>
  <div class="flex-grow-1 pb-12">
    <AdminTitle title="General Settings"></AdminTitle>

    <v-card class="mt-5" outlined>
      <v-form ref="settingsForm">
        <v-simple-table>
          <thead>
            <tr>
              <th width="50%">Name</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <v-switch 
                  v-model="data.enableFreeTrial" 
                  label="Employer Free Trial" 
                  color="success"
                  dense
                ></v-switch>  
              </td>
              <td>
                <v-text-field
                  v-model.number="data.freeTrial"
                  :rules="[rules.required]"
                  suffix="days"
                  type="number"
                  hide-details
                  outlined
                  dense
                ></v-text-field>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-form>
    </v-card>
    <div class="mt-3 text-right">
      <v-btn
        @click="validateForm()"
        :loading="status.updating"
        color="accent gradient"
      >Update</v-btn>
    </div>
  </div>
</template>

<script>
import rules from '@/rules'
import { mapState, mapActions } from 'vuex'

export default {
  metaInfo: {
    title: 'General Settings'
  },

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      rules,
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      data: state => state.settings.data,
      status: state => state.settings.status,
    }),
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('settings', [
      'updateSettings',
      'getSettings'
    ]),

    validateForm() {
      if (this.$refs.settingsForm.validate()) {
        this.updateSettings()
      }
    }
  },

  /*------------------------------------------------------------------------------
   * MOUNTED
   *----------------------------------------------------------------------------*/
  mounted() {
    this.getSettings()
  }
}
</script>